import React, { useState } from 'react';
import { AppDispatch } from '../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getUserCredentialBackup, sendNotification, sendNotificationByTopic } from '../store/actions/notificationActions';
import { selectState } from '../store/slices/notificationSlice';
import './sendNotifications.css';
import LoadingCercleGreen from '../assets/images/loadingCercleGreen.gif';
import LoadingCercle from '../assets/images/loadingCercle.gif';

const SendNotification = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { deviceTokens, loading, error , notifTypes} = useSelector(selectState);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('What is Lorem Ipsum?Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.');
  const [userId, setUserId] = useState('');
  const [topic, setTopic] = useState('active');
  const [selectedToken, setSelectedToken] = useState(deviceTokens? deviceTokens[0] : '');
  const [disabledByToken,setDisabledByToken] = useState(true);
  const topics = ['all', 'active', 'subscribed', 'unsubscribed', ];
  const [selectedNotificationType, setSelectedNotificationType] = useState(notifTypes? notifTypes[0] : '')

  const handleTopicChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTopic(e.target.value);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisabledByToken(e.target.value.length > 0? true : false)
    setTitle(e.target.value);
  };
  
  const handleUserIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserId(e.target.value);
  };
  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDisabledByToken(e.target.value.length > 0? true : false)
    setMessage(e.target.value);
  };

  const handleTokenChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedToken(e.target.value);
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedNotificationType(e.target.value);
  };

  const handleClick = () => {
    if (selectedToken) {
      dispatch(sendNotification({ message, title, token: selectedToken , type: selectedNotificationType})); // Include all necessary data
    } else {
      // Handle case where no token is selected
      console.error('Please select a device token');
    }
  };

  const getUserBackup = () => {
    dispatch(getUserCredentialBackup(userId)); // Include all necessary data
  }

  const sendNotifByTopic = () => {
    if (topic) {
      dispatch(sendNotificationByTopic({ message, title, topic, type: selectedNotificationType })); // Include all necessary data
    } else {
      console.error('Please select a topic');
    }
  }
 

  return (
    <div className="container">
    {/** <div className="notification-form">
      <input
          type="text"
          name="userId"
          placeholder="UserId"
          className="notification-input"
          value={userId}
          onChange={e => handleUserIdChange(e)}
        />
      <button onClick={getUserBackup}>Get user backup</button>
    </div> */}
   
    {/*
    <div className="notification-form">
      <h2 className='title'>Create Notification</h2>
      
      <select name="device-token-select" className="device-token-select" value={selectedToken} onChange={e => handleTokenChange(e)}>
      <option value="" disabled>Select a device token</option>
        {deviceTokens && deviceTokens.map((token, index) => (
          <option key={index} value={token}>
            {token}
          </option>
        ))}
      </select>
  
      <select name="notification-type-select" className="device-token-select" value={selectedNotificationType} onChange={e => handleTypeChange(e)}>
      <option value="" disabled>Select the type</option>
        {notifTypes && notifTypes.map((type, index) => (
          <option key={index} value={type}>
            {type}
          </option>
        ))}
      </select>
      <input
        type="text"
        name="title"
        placeholder="Title"
        className="notification-input"
        value={title}
        onChange={e => handleTitleChange(e)}
      />
      <textarea
        name="message"
        value={message}
        placeholder="Put message here..."
        className="notification-textarea"
        onChange={e => handleMessageChange(e)}
      />
      <button className={loading? 'loadingButton' : 'button'} onClick={handleClick} disabled={title.length == 0 || message.length == 0}>{loading ?  <img className='loadingIcon' src={LoadingCercle} alt="loadingGif" /> : 'Send Message'}</button>
      <h5 className='error'>{error}</h5>
    </div>
   */}
    <div className="notification-form">
     <h1 className='title'>Send A Notification By Topic</h1>
     <hr className="divider"></hr>
      <select className="device-token-select" value={selectedNotificationType} onChange={e => handleTypeChange(e)}>
      <option value="" disabled>Select the type</option>
        {notifTypes && notifTypes.map((type, index) => (
          <option key={index} value={type}>
            {type}
          </option>
        ))}
      </select>

     <select className="device-token-select" value={topic} onChange={e => handleTopicChange(e)}>
     <option value="" disabled>Select a topic</option>
       {topics && topics.map((topic, index) => (
         <option key={index} value={topic}>
           {topic}
         </option>
       ))}
     </select>
     <input
       type="text"
       name="title"
       placeholder="Title"
       className="notification-input"
       value={title}
       onChange={e => handleTitleChange(e)}
     />
     <textarea
       name="message"
       value={message}
       placeholder="Put message here..."
       className="notification-textarea"
       onChange={e => handleMessageChange(e)}
     />
     <button className={loading? 'loadingButton' : 'button'} onClick={sendNotifByTopic} disabled={title.length == 0 || message.length == 0 || loading }>{loading ?  <img className='loadingIcon' src={LoadingCercle} alt="loadingGif" /> : 'Send Notif By Topic'}</button>
   </div>
  </div>
  )
}

export default SendNotification