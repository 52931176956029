import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../store/store';
import { login } from '../store/actions/authActions';
import { selectState } from '../store/slices/authSlice';
import {ReactComponent as Logo} from '../assets/images/LOGO.svg';
import '../assets/styles/index.css';
function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { error, loading, isAuthenticated } = useSelector(selectState);

  const validate = () => {
    let isValid = true;

    if (!email) {
      setEmailError('Email is required.');
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Please enter a valid email.');
      isValid = false;
    } else {
      setEmailError(null);
    }

    if (!password) {
      setPasswordError('Password is required.');
      isValid = false;
    } else if (password.length < 6) {
      setPasswordError('Password must be at least 6 characters.');
      isValid = false;
    } else {
      setPasswordError(null);
    }

    return isValid;
  };

  const handleLogin = async () => {
    if (validate()) {
      await dispatch(login({ email, password }));
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
        console.log('isauthenticated', isAuthenticated);
        navigate('/sendNotification');
      }
  }, [isAuthenticated])
  

  return (
    <div style={styles.container}>
     
      <Logo width={200} height={200}/>
      <h2 style={styles.title}>Take Back The Controle Of Your Wealth</h2>
     
      <div>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        style={styles.input}
        className='custom_input'
      />
      {emailError && <p style={styles.error}>{emailError}</p>}
      
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        style={styles.input}
        className='custom_input'
      />
      {passwordError && <p style={styles.error}>{passwordError}</p>}
      
      <button onClick={handleLogin} disabled={loading} style={styles.button}>
        {loading ? 'Logging in...' : 'Login'}
      </button>
      
      {error && <p style={styles.error}>{error.toString()}</p>}
      </div>
    </div>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      padding: '20px',
      width: '300px',
      margin: 'auto',
      maxHeight: '60%'
    },
    title: {
      color: '#720F83',
      textAlign: 'center',
      marginBottom: '5vh',
      fontWeight: '400',
    },
    input: {
      padding: '10px',
      marginBottom: '10px',
      borderRadius: '15px',
      border: '1px solid #ccc',
      width: '100%',
      boxSizing: 'border-box',
    },
    button: {
      backgroundColor: '#720F83',
      border: 'none',
      color: 'white',
      padding: '10px 20px',
      textAlign: 'center',
      textDecoration: 'none',
      display: 'inline-block',
      fontSize: '16px',
      cursor: 'pointer',
      borderRadius: '15px',
      width: '100%',
      fontWeight: 700,
      boxShadow: '2px 2px 2px gray',
      background: 'linear-gradient(to right, #1D3AAB, #880098)',
    },
    error: {
      color: 'red',
      marginBottom: '10px',
      textAlign: 'left',
      width: '100%',
      maxWidth: '300px',
    },
  };
export default Login;
