import {configureStore} from '@reduxjs/toolkit';
import notificationSlice from './slices/notificationSlice';
import authSlice from './slices/authSlice';

export const store = configureStore({
  reducer: {
    notification_slice: notificationSlice,
    auth_slice: authSlice
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({serializableCheck: false}),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
