import { signInWithEmailAndPassword, UserCredential, signOut } from "firebase/auth";
import { auth } from '../../Firebase'
import { createAsyncThunk } from '@reduxjs/toolkit';

interface loginArgs {
  email: string;
  password: string ;
}

export const login = createAsyncThunk<UserCredential , loginArgs>(
  'auth_slice/login',
  async ({email, password}, thunkAPI) => {
    // console.log('messaging  get token ', token);
    try {
      
      const response =await signInWithEmailAndPassword(auth, email, password);
      console.log('axios', response)
      return response

    } catch (e) {
      console.log('-------------- Error to login' + e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const logout = createAsyncThunk('auth/logout', async () => {
    await signOut(auth);
  });