import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

interface sendNotificationArgs {
  message: string | undefined;
  title: string | undefined;
  token: string;
  type: string;
}

interface sendNotificationTopicArgs {
  message: string | undefined;
  title: string | undefined;
  topic: string;
  type: string;
}
export interface UserCredentialProvidersBackup {
  userId: string;
  id: string;
  moneyhubId: string;
  yodleeSalt: string;
  yodleeLoginName: string;
  powensId: string;
  powensToken: string;
  profilePic: string;
  createdAt: string;
  updatedAt: string;
  devices: Device[];
}
 type Device = {
  token: string;
  deviceId: string;
 }

export const getUserCredentialBackup = createAsyncThunk<UserCredentialProvidersBackup, string>(
  'notifications_slice/getUserCredentialBackup',
  async (userId, thunkAPI) => {

  try {
    const res = await fetch(`https://b14h7isg51.execute-api.eu-west-2.amazonaws.com/staging/bckp/account-backup?userId=${userId}`);
    const data = await res.json();
    console.log('userBackup', data)
    const payload = data.response.data as UserCredentialProvidersBackup[];
    return payload[0];
  } catch (e) {
    console.log('Error Fetching getUserCredentialBackup' + e);
    return thunkAPI.rejectWithValue(e);
  }
}
);

export const sendNotification = createAsyncThunk<string | undefined, sendNotificationArgs>(
  'notifications_slice/sendNotification',
  async ({message, title, token, type}, thunkAPI) => {
    // console.log('messaging  get token ', token);
    try {
      const body = {
        "message":{
           "token": token,
           //"condition": `'enabled_push_notification' in topics`, 
           "data": {
            "type": type
           },
           "notification":{
             "body":message,
             "title":title,
             
           }
        }
      }
      
      const response = await axios.post('https://pushnotifications-c5yieuvjca-uc.a.run.app', body, {
        headers: {
          'Content-Type': 'application/json',
         
        }});
      console.log('axios', response)

    } catch (e) {
      console.log('-------------- Error register User Device Token' + e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);


export const sendNotificationByTopic = createAsyncThunk<string | undefined, sendNotificationTopicArgs>(
  'notifications_slice/sendNotification',
  async ({message, title, topic, type}, thunkAPI) => {
    //"condition": `'<span class="math-inline">\{topic\}' in topics && topics\['</span>${topic}'] == 'enabled_push_notification'`,
    // console.log('messaging  get token ', token);// "condition": `'${topic}' in topics && 'enabled_push_notification' in topics`,
    try {
      const body = {
        "message":{
          "condition": `'enabled_push_notification' in topics && '${topic}' in topics && '${type}' in topics`, 
          //"topic": topic,
          "data" : { 
            "type": type
          },
          "notification": {
            "body":message,
            "title":title,
          }
          
        }
      } //http://127.0.0.1:5001/elisyanwealth/us-central1/pushNotifications
      const response = await axios.post('https://pushnotifications-c5yieuvjca-uc.a.run.app', body, {
        headers: {
          'Content-Type': 'application/json',
        }});
      console.log('axios', response)

    } catch (e) {
      console.log('-------------- Error register User Device Token' + e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);