import React from 'react';
import './App.css';
import SendNotification from './components/sendNotification';
import Login from './views/login';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectState } from './store/slices/authSlice';

interface ProtectedRouteProps {
  element: JSX.Element;
  isAuthenticated: boolean;
}

function ProtectedRoute({ element, isAuthenticated }: ProtectedRouteProps) {
  return isAuthenticated ? element : <Navigate to="/login" />;
}

const App = () => {
  const { isAuthenticated } = useSelector(selectState);
  

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sendNotification" element={<ProtectedRoute element={<SendNotification />} isAuthenticated={isAuthenticated} />} />
      </Routes>
  </Router>
  );
}

export default App;
